import React from 'react';

const OutsourcingOutsourcing4 = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1585 24.9157C22.4606 24.9083 20.8344 24.2307 19.6338 23.0301C18.4331 21.8295 17.7552 20.2033 17.7478 18.5054V13.4103C17.7478 11.7101 18.4233 10.0796 19.6256 8.87741C20.8278 7.6752 22.4583 7 24.1585 7C25.8587 7 27.4892 7.6752 28.6914 8.87741C29.8936 10.0796 30.5691 11.7101 30.5691 13.4103V18.5054C30.5618 20.2033 29.8838 21.8295 28.6832 23.0301C27.4826 24.2307 25.8564 24.9083 24.1585 24.9157ZM24.1585 8.39969C22.8295 8.39969 21.5549 8.92731 20.6152 9.86703C19.6755 10.8068 19.1475 12.0814 19.1475 13.4103V18.5054C19.1475 19.8343 19.6755 21.1086 20.6152 22.0483C21.5549 22.9881 22.8295 23.516 24.1585 23.516C25.4874 23.516 26.762 22.9881 27.7018 22.0483C28.6415 21.1086 29.1695 19.8343 29.1695 18.5054V13.4103C29.1695 12.0814 28.6415 10.8068 27.7018 9.86703C26.762 8.92731 25.4874 8.39969 24.1585 8.39969Z"
      fill="#C383EC"
    />
    <path
      d="M31.1009 41.121H16.7121C15.4968 41.1136 14.3334 40.6275 13.474 39.7681C12.6146 38.9087 12.1284 37.7453 12.1211 36.53V31.2671C12.1858 30.6402 12.3938 30.0367 12.7287 29.5028C13.0636 28.9689 13.5166 28.519 14.0528 28.1879C15.1368 27.3517 16.3725 26.7337 17.6918 26.3682L22.1711 25.1367C22.2575 25.1113 22.3478 25.1035 22.4373 25.1138C22.5267 25.1241 22.6132 25.1521 22.6915 25.1965C22.7699 25.2409 22.8387 25.3007 22.8935 25.3721C22.9483 25.4436 22.9881 25.5252 23.0107 25.6124C23.0627 25.7905 23.0418 25.9824 22.9526 26.1451C22.8634 26.3078 22.7129 26.4284 22.5347 26.4803L18.0838 27.7119C16.9368 28.0222 15.862 28.555 14.9205 29.2797C14.2766 29.7836 13.5208 30.5393 13.5208 31.2671V36.53C13.5208 36.9491 13.6034 37.3641 13.7637 37.7513C13.9241 38.1385 14.1594 38.4904 14.4557 38.7867C14.7521 39.083 15.1036 39.318 15.4908 39.4783C15.878 39.6387 16.293 39.7213 16.7121 39.7213H31.1009C31.9424 39.7139 32.7471 39.3745 33.3395 38.7768C33.932 38.1791 34.2643 37.3715 34.2642 36.53V31.2671C34.2642 30.5393 33.5084 29.7836 32.8926 29.2797C31.951 28.555 30.8762 28.0222 29.7293 27.7119L25.3901 26.5083C25.2119 26.4564 25.0618 26.3355 24.9725 26.1728C24.8833 26.01 24.8624 25.8186 24.9144 25.6404C24.9664 25.4622 25.0869 25.312 25.2496 25.2228C25.4124 25.1336 25.6039 25.1127 25.782 25.1647L30.0932 26.3682C31.4234 26.7276 32.6694 27.346 33.7602 28.1879C34.2884 28.5252 34.7339 28.9773 35.0635 29.5104C35.3931 30.0435 35.5983 30.6438 35.6639 31.2671V36.53C35.6639 37.7427 35.1841 38.9062 34.3291 39.7664C33.4742 40.6266 32.3137 41.1136 31.1009 41.121Z"
      fill="#C383EC"
    />
    <path
      d="M22.4508 26.3964C22.2674 26.3894 22.0933 26.3136 21.9635 26.1838C21.8338 26.054 21.7579 25.8799 21.751 25.6965V24.3248C21.751 24.1392 21.8248 23.9613 21.956 23.83C22.0873 23.6988 22.2652 23.625 22.4508 23.625C22.6364 23.625 22.8144 23.6988 22.9456 23.83C23.0769 23.9613 23.1507 24.1392 23.1507 24.3248V25.8086C23.1238 25.9742 23.0382 26.1244 22.9097 26.2323C22.7813 26.3402 22.6186 26.3985 22.4508 26.3964Z"
      fill="#C383EC"
    />
    <path
      d="M25.6979 26.4244C25.5123 26.4244 25.3343 26.3506 25.2031 26.2193C25.0718 26.0881 24.998 25.9101 24.998 25.7245V24.3248C24.998 24.1392 25.0718 23.9613 25.2031 23.83C25.3343 23.6988 25.5123 23.625 25.6979 23.625C25.8835 23.625 26.0618 23.6988 26.193 23.83C26.3243 23.9613 26.3977 24.1392 26.3977 24.3248V25.8366C26.3709 26.0022 26.2856 26.1524 26.1572 26.2603C26.0287 26.3682 25.8656 26.4265 25.6979 26.4244Z"
      fill="#C383EC"
    />
    <path
      d="M24.0184 32.3354C22.7926 32.1675 21.6794 31.5315 20.9122 30.5608C20.145 29.5901 19.7835 28.36 19.9034 27.1286C19.8769 26.8025 19.8769 26.4749 19.9034 26.1489C19.9311 25.9678 20.0289 25.8046 20.1754 25.6947C20.322 25.5848 20.5057 25.5367 20.6873 25.5608C20.8707 25.595 21.0338 25.6985 21.1432 25.8495C21.2526 26.0006 21.2998 26.1878 21.2751 26.3727C21.2612 26.6244 21.2612 26.8768 21.2751 27.1286C21.2751 29.2561 22.4788 31.0197 24.0745 31.0197C25.6701 31.0197 26.8738 29.2561 26.8738 27.1286C26.8877 26.8768 26.8877 26.6244 26.8738 26.3727C26.8579 26.2806 26.8606 26.1866 26.8817 26.0956C26.9028 26.0046 26.9417 25.9187 26.9965 25.843C27.0513 25.7674 27.1206 25.7032 27.2005 25.6547C27.2804 25.6063 27.3692 25.5743 27.4616 25.5608C27.6432 25.5367 27.8273 25.5848 27.9738 25.6947C28.1204 25.8046 28.2178 25.9678 28.2455 26.1489C28.272 26.4749 28.272 26.8025 28.2455 27.1286C28.3635 28.3772 27.9869 29.6223 27.1964 30.596C26.4059 31.5697 25.2646 32.1942 24.0184 32.3354Z"
      fill="#C383EC"
    />
    <path
      d="M26.2582 14.1627C25.6572 14.1705 25.0623 14.0409 24.5189 13.7837C23.9755 13.5266 23.4981 13.1487 23.123 12.6789C22.8799 12.365 22.5485 12.131 22.1713 12.0071C21.3595 11.8112 20.2396 12.4551 18.9519 13.8548C18.8211 13.983 18.6451 14.0547 18.4619 14.0547C18.2787 14.0547 18.103 13.983 17.9722 13.8548C17.9053 13.792 17.8518 13.7159 17.8154 13.6317C17.7789 13.5474 17.76 13.4566 17.76 13.3648C17.76 13.273 17.7789 13.1822 17.8154 13.0979C17.8518 13.0136 17.9053 12.9379 17.9722 12.8751C19.6518 11.0275 21.1635 10.2995 22.5072 10.6074C23.1564 10.7939 23.7328 11.175 24.1587 11.6992C24.4298 12.0346 24.7746 12.303 25.1665 12.4831C25.8943 12.8191 27.0421 13.0429 29.4496 11.6712C29.6075 11.5999 29.7862 11.5897 29.9512 11.6425C30.1162 11.6953 30.2557 11.8075 30.3428 11.9572C30.4299 12.107 30.4582 12.2839 30.4225 12.4534C30.3867 12.6229 30.2895 12.7732 30.1494 12.8751C28.985 13.6324 27.6446 14.0758 26.2582 14.1627Z"
      fill="#C383EC"
    />
    <path
      d="M37.6234 23.0969C36.0914 23.0969 34.622 22.4901 33.5361 21.4094C32.4502 20.3288 31.8363 18.862 31.8289 17.33V12.767C31.9308 11.3034 32.5842 9.93299 33.6571 8.93222C34.7299 7.93145 36.1422 7.375 37.6094 7.375C39.0766 7.375 40.4892 7.93145 41.5621 8.93222C42.6349 9.93299 43.2884 11.3034 43.3903 12.767V17.33C43.3903 18.8594 42.7826 20.3263 41.7012 21.4077C40.6197 22.4892 39.1528 23.0969 37.6234 23.0969ZM37.6234 8.40016C37.0476 8.39647 36.4769 8.50664 35.9439 8.72445C35.4108 8.94226 34.926 9.26308 34.5175 9.66897C34.109 10.0749 33.7846 10.5578 33.5634 11.0895C33.3422 11.6212 33.2285 12.1912 33.2285 12.767V17.33C33.3167 18.4314 33.8163 19.4591 34.6282 20.2086C35.4401 20.9582 36.5044 21.3746 37.6094 21.3746C38.7144 21.3746 39.779 20.9582 40.5909 20.2086C41.4028 19.4591 41.9025 18.4314 41.9906 17.33V12.767C41.9906 11.6088 41.5305 10.4982 40.7115 9.67922C39.8926 8.86024 38.7816 8.40016 37.6234 8.40016Z"
      fill="#475467"
    />
    <path
      d="M28.7496 27.2949C28.6543 27.2972 28.5596 27.2782 28.4725 27.2395C28.3853 27.2008 28.3077 27.143 28.2456 27.0707C28.1786 27.0079 28.1252 26.9322 28.0887 26.8479C28.0522 26.7636 28.0337 26.6728 28.0337 26.581C28.0337 26.4892 28.0522 26.3981 28.0887 26.3138C28.1252 26.2295 28.1786 26.1538 28.2456 26.091C28.3537 25.9737 28.4759 25.8703 28.6095 25.7831C29.5785 25.0232 30.6934 24.4703 31.8849 24.1592L35.8598 23.0398C35.948 23.014 36.0404 23.0059 36.1318 23.0159C36.2231 23.0258 36.3119 23.0537 36.3925 23.0979C36.5553 23.1871 36.6758 23.3373 36.7277 23.5154C36.7711 23.6971 36.7429 23.8887 36.6491 24.0502C36.5553 24.2118 36.403 24.3311 36.2237 24.3834L32.2768 25.5032C31.2577 25.7586 30.3038 26.226 29.4775 26.8749L29.1696 27.1267C29.05 27.2243 28.9034 27.2831 28.7496 27.2949Z"
      fill="#475467"
    />
    <path
      d="M43.8661 37.428H35.0761C34.8905 37.428 34.7122 37.3542 34.5809 37.223C34.4497 37.0917 34.3762 36.9138 34.3762 36.7282C34.3762 36.5426 34.4497 36.3643 34.5809 36.233C34.7122 36.1018 34.8905 36.0283 35.0761 36.0283H43.8661C44.6086 36.0283 45.3204 35.7332 45.8454 35.2082C46.3704 34.6832 46.6655 33.9714 46.6655 33.229V28.5539C46.6655 28.05 46.2454 27.4622 45.4616 26.8463C44.6318 26.2029 43.6792 25.7362 42.6623 25.4746L38.8271 24.3828C38.7303 24.3672 38.6379 24.3312 38.5558 24.2776C38.4737 24.2239 38.4039 24.1536 38.3508 24.0712C38.2976 23.9888 38.2623 23.8964 38.2472 23.7995C38.2322 23.7027 38.2377 23.6037 38.2633 23.5091C38.2889 23.4144 38.3343 23.3261 38.3962 23.25C38.4581 23.174 38.5353 23.1119 38.6228 23.0676C38.7102 23.0233 38.8058 22.998 38.9037 22.9931C39.0016 22.9882 39.0996 23.0039 39.1911 23.0392L43.0542 24.131C44.2435 24.4479 45.357 24.9999 46.3293 25.7545C46.8139 26.0567 47.2226 26.4665 47.5236 26.9519C47.8245 27.4373 48.01 27.9854 48.0652 28.5539V33.229C48.0689 33.7814 47.9626 34.329 47.7529 34.8402C47.5432 35.3513 47.2342 35.8157 46.8435 36.2064C46.4529 36.597 45.9885 36.906 45.4773 37.1157C44.9662 37.3254 44.4186 37.4317 43.8661 37.428Z"
      fill="#475467"
    />
    <path
      d="M36.112 24.3274C35.9264 24.3274 35.7485 24.2536 35.6172 24.1224C35.486 23.9911 35.4122 23.8132 35.4122 23.6275V22.3119C35.3915 22.2103 35.3938 22.1054 35.4187 22.0047C35.4436 21.904 35.4904 21.8099 35.556 21.7296C35.6217 21.6493 35.7043 21.5848 35.798 21.5403C35.8917 21.4959 35.9943 21.4727 36.098 21.4727C36.2017 21.4727 36.304 21.4959 36.3977 21.5403C36.4914 21.5848 36.574 21.6493 36.6396 21.7296C36.7053 21.8099 36.7525 21.904 36.7774 22.0047C36.8023 22.1054 36.8045 22.2103 36.7838 22.3119V23.6275C36.784 23.8084 36.714 23.9823 36.5887 24.1128C36.4635 24.2433 36.2927 24.3202 36.112 24.3274Z"
      fill="#475467"
    />
    <path
      d="M38.9674 24.3293C38.7818 24.3293 38.6035 24.2555 38.4723 24.1242C38.341 23.993 38.2676 23.815 38.2676 23.6294V22.2858C38.2676 22.1002 38.341 21.9222 38.4723 21.791C38.6035 21.6597 38.7818 21.5859 38.9674 21.5859C39.153 21.5859 39.331 21.6597 39.4622 21.791C39.5935 21.9222 39.6673 22.1002 39.6673 22.2858V23.6294C39.6673 23.815 39.5935 23.993 39.4622 24.1242C39.331 24.2555 39.153 24.3293 38.9674 24.3293Z"
      fill="#475467"
    />
    <path
      d="M37.4833 29.6986C36.3656 29.5383 35.3544 28.9483 34.6645 28.0545C33.9745 27.1607 33.66 26.0332 33.788 24.9114C33.788 24.6315 33.788 24.3237 33.788 24.0438C33.8016 23.9514 33.8335 23.8626 33.882 23.7827C33.9304 23.7028 33.9942 23.6335 34.0699 23.5787C34.1456 23.5239 34.2314 23.4846 34.3224 23.4635C34.4134 23.4424 34.5079 23.4397 34.5999 23.4557C34.781 23.4834 34.9441 23.5811 35.0541 23.7277C35.164 23.8742 35.2118 24.058 35.1877 24.2396C35.1738 24.4633 35.1738 24.6877 35.1877 24.9114C35.1877 26.759 36.2516 28.2989 37.4833 28.2989C38.7151 28.2989 39.7787 26.759 39.7787 24.9114C39.7925 24.6877 39.7925 24.4633 39.7787 24.2396C39.7506 24.1433 39.7437 24.0422 39.7582 23.943C39.7726 23.8437 39.8081 23.7487 39.8624 23.6645C39.9167 23.5802 39.9885 23.5088 40.0729 23.4546C40.1573 23.4005 40.2525 23.3649 40.3517 23.3508C40.451 23.3366 40.5522 23.344 40.6484 23.3723C40.7445 23.4006 40.8333 23.4494 40.9091 23.5151C40.9848 23.5808 41.0455 23.6618 41.0871 23.753C41.1288 23.8442 41.1503 23.9435 41.1503 24.0438C41.1781 24.3324 41.1781 24.6228 41.1503 24.9114C41.2789 26.029 40.9686 27.1529 40.2844 28.046C39.6003 28.9391 38.5959 29.5317 37.4833 29.6986Z"
      fill="#475467"
    />
    <path
      d="M39.4989 13.5237C38.9673 13.5255 38.4416 13.4108 37.9591 13.1877C37.4541 12.9487 37.0058 12.6053 36.6435 12.18C36.4517 11.9114 36.1758 11.7142 35.8596 11.6199C35.1597 11.452 34.1799 12.012 33.0602 13.2158C32.9294 13.344 32.7537 13.4157 32.5705 13.4157C32.3873 13.4157 32.2113 13.344 32.0805 13.2158C32.0127 13.1505 31.9591 13.072 31.9223 12.9854C31.8855 12.8989 31.8662 12.8058 31.8662 12.7117C31.8662 12.6177 31.8855 12.5246 31.9223 12.438C31.9591 12.3514 32.0127 12.2733 32.0805 12.208C33.5921 10.5564 34.8798 9.91229 36.1955 10.1922C36.7834 10.3489 37.3043 10.6931 37.6792 11.1723C37.9221 11.4578 38.2167 11.6952 38.5472 11.8721C39.191 12.1521 40.1987 12.348 42.2982 11.1443C42.3774 11.0867 42.468 11.0461 42.5638 11.0257C42.6595 11.0052 42.7584 11.0051 42.8542 11.0253C42.95 11.0455 43.0404 11.0855 43.1197 11.1429C43.1991 11.2003 43.2656 11.2739 43.3149 11.3585C43.3641 11.4432 43.3952 11.5369 43.4058 11.6343C43.4164 11.7316 43.4062 11.8301 43.3764 11.9234C43.3465 12.0166 43.2973 12.1028 43.2322 12.1759C43.167 12.249 43.0873 12.3075 42.9981 12.3478C41.9549 13.0397 40.7482 13.4453 39.4989 13.5237Z"
      fill="#475467"
    />
    <path
      d="M10.6935 23.0969C9.16146 23.0969 7.69173 22.4901 6.60582 21.4094C5.5199 20.3288 4.90633 18.862 4.89893 17.33V12.767C5.00084 11.3034 5.65425 9.93299 6.72713 8.93222C7.8 7.93145 9.21229 7.375 10.6795 7.375C12.1466 7.375 13.5593 7.93145 14.6321 8.93222C15.705 9.93299 16.3584 11.3034 16.4603 12.767V17.33C16.4603 18.0873 16.311 18.8372 16.0212 19.5368C15.7314 20.2365 15.3067 20.8722 14.7712 21.4077C14.2357 21.9432 13.6 22.3679 12.9003 22.6577C12.2007 22.9476 11.4508 23.0969 10.6935 23.0969ZM10.6935 8.40016C10.1176 8.39647 9.54699 8.50664 9.01392 8.72445C8.48085 8.94226 7.99606 9.26308 7.58758 9.66897C7.17909 10.0749 6.85471 10.5578 6.6335 11.0895C6.41229 11.6212 6.2986 12.1912 6.29861 12.767V17.33C6.38673 18.4314 6.88642 19.4591 7.6983 20.2086C8.51018 20.9582 9.5745 21.3746 10.6795 21.3746C11.7844 21.3746 12.8491 20.9582 13.661 20.2086C14.4729 19.4591 14.9725 18.4314 15.0607 17.33V12.767C15.0607 12.1935 14.9476 11.6258 14.7282 11.096C14.5087 10.5662 14.1871 10.0847 13.7816 9.67922C13.3761 9.2737 12.8947 8.95178 12.3648 8.73231C11.835 8.51285 11.267 8.40016 10.6935 8.40016Z"
      fill="#475467"
    />
    <path
      d="M12.9331 37.4286H4.14302C3.04164 37.4212 1.98792 36.9785 1.21174 36.197C0.435558 35.4156 -2.48044e-05 34.3586 1.05978e-09 33.2572V28.5825C0.0551996 28.014 0.240324 27.4659 0.541285 26.9805C0.842246 26.4951 1.25096 26.0853 1.7356 25.7831C2.7079 25.0285 3.82171 24.4762 5.01098 24.1592L8.98587 23.0398C9.0741 23.014 9.16652 23.0059 9.25788 23.0159C9.34924 23.0258 9.43769 23.0537 9.51827 23.0979C9.68102 23.1871 9.80187 23.3373 9.85384 23.5154C9.89725 23.6971 9.86904 23.8887 9.77525 24.0502C9.68145 24.2118 9.52911 24.3311 9.34981 24.3834L5.4026 25.5032C4.38343 25.7586 3.42957 26.226 2.60322 26.8749C1.8194 27.4908 1.39969 28.0786 1.39969 28.5825V33.2572C1.39969 33.9996 1.69449 34.7118 2.21947 35.2368C2.74446 35.7618 3.45662 36.0566 4.19906 36.0566H12.9891C13.1747 36.0566 13.3527 36.1304 13.4839 36.2616C13.6152 36.3929 13.689 36.5708 13.689 36.7564C13.6855 36.8509 13.663 36.9436 13.6227 37.0291C13.5824 37.1146 13.5252 37.1912 13.4545 37.254C13.3839 37.3167 13.3012 37.3644 13.2116 37.3944C13.122 37.4244 13.0273 37.4362 12.9331 37.4286Z"
      fill="#475467"
    />
    <path
      d="M19.2318 27.2662C19.0586 27.2663 18.8905 27.2069 18.7558 27.098L18.5039 26.8742C17.6741 26.2308 16.7215 25.7641 15.7046 25.5025L11.8694 24.4107C11.7787 24.388 11.6934 24.3474 11.6186 24.2911C11.5439 24.2349 11.4811 24.1639 11.4341 24.083C11.3871 24.0021 11.357 23.9127 11.3452 23.8199C11.3334 23.7271 11.3401 23.6329 11.3654 23.5428C11.4174 23.3646 11.5382 23.2144 11.701 23.1252C11.8637 23.0359 12.0552 23.0151 12.2334 23.0671L16.0965 24.1585C17.2858 24.4755 18.3996 25.0278 19.3719 25.7824L19.6798 26.0343C19.808 26.1651 19.8797 26.3411 19.8797 26.5243C19.8797 26.7075 19.808 26.8831 19.6798 27.014C19.6264 27.0845 19.559 27.1432 19.4819 27.1865C19.4049 27.2299 19.3198 27.2572 19.2318 27.2662Z"
      fill="#475467"
    />
    <path
      d="M9.18209 24.3274C8.99648 24.3274 8.81819 24.2536 8.68694 24.1224C8.55569 23.9911 8.48225 23.8132 8.48225 23.6275V22.3119C8.46161 22.2103 8.46384 22.1054 8.48874 22.0047C8.51364 21.904 8.56048 21.8099 8.62611 21.7296C8.69175 21.6493 8.77434 21.5848 8.86805 21.5403C8.96177 21.4959 9.06437 21.4727 9.16808 21.4727C9.2718 21.4727 9.37406 21.4959 9.46777 21.5403C9.56148 21.5848 9.64407 21.6493 9.70971 21.7296C9.77535 21.8099 9.82252 21.904 9.84742 22.0047C9.87232 22.1054 9.87455 22.2103 9.85391 22.3119V23.6275C9.85406 23.8084 9.78404 23.9823 9.65879 24.1128C9.53354 24.2433 9.36281 24.3202 9.18209 24.3274Z"
      fill="#475467"
    />
    <path
      d="M12.037 24.3293C11.8514 24.3293 11.6734 24.2555 11.5422 24.1242C11.4109 23.993 11.3372 23.815 11.3372 23.6294V22.2858C11.3372 22.1002 11.4109 21.9222 11.5422 21.791C11.6734 21.6597 11.8514 21.5859 12.037 21.5859C12.2226 21.5859 12.4009 21.6597 12.5322 21.791C12.6634 21.9222 12.7368 22.1002 12.7368 22.2858V23.6294C12.7368 23.815 12.6634 23.993 12.5322 24.1242C12.4009 24.2555 12.2226 24.3293 12.037 24.3293Z"
      fill="#475467"
    />
    <path
      d="M10.5537 29.6986C9.43596 29.5383 8.42476 28.9483 7.7348 28.0545C7.04485 27.1607 6.7303 26.0332 6.85829 24.9114C6.85829 24.6315 6.85829 24.3237 6.85829 24.0438C6.87187 23.9514 6.90384 23.8626 6.95226 23.7827C7.00069 23.7028 7.06455 23.6335 7.14021 23.5787C7.21587 23.5239 7.30174 23.4846 7.39274 23.4635C7.48374 23.4424 7.57817 23.4397 7.67022 23.4557C7.85129 23.4834 8.01411 23.5811 8.12402 23.7277C8.23393 23.8742 8.28207 24.058 8.25798 24.2396C8.24411 24.4633 8.24411 24.6877 8.25798 24.9114C8.25798 26.759 9.32193 28.2989 10.5537 28.2989C11.7854 28.2989 12.849 26.759 12.849 24.9114C12.8629 24.6877 12.8629 24.4633 12.849 24.2396C12.8209 24.1433 12.8141 24.0422 12.8285 23.943C12.8429 23.8437 12.8784 23.7487 12.9327 23.6645C12.987 23.5802 13.0588 23.5088 13.1432 23.4546C13.2276 23.4005 13.3228 23.3649 13.4221 23.3508C13.5213 23.3366 13.6225 23.344 13.7187 23.3723C13.8149 23.4006 13.9036 23.4494 13.9794 23.5151C14.0551 23.5808 14.1158 23.6618 14.1574 23.753C14.1991 23.8442 14.2207 23.9435 14.2207 24.0438C14.2484 24.3324 14.2484 24.6228 14.2207 24.9114C14.3492 26.029 14.0389 27.1529 13.3547 28.046C12.6706 28.9391 11.6662 29.5317 10.5537 29.6986Z"
      fill="#475467"
    />
    <path
      d="M12.5411 13.5237C12.0091 13.5292 11.4826 13.4144 11.0013 13.1877C10.4964 12.9487 10.048 12.6053 9.6857 12.18C9.49396 11.9114 9.21806 11.7142 8.90179 11.6199C8.20195 11.452 7.25016 12.012 6.10242 13.2158C5.9716 13.344 5.79592 13.4157 5.61273 13.4157C5.42955 13.4157 5.25353 13.344 5.12271 13.2158C5.05497 13.1505 5.00129 13.072 4.96449 12.9854C4.92769 12.8989 4.90845 12.8058 4.90845 12.7117C4.90845 12.6177 4.92769 12.5246 4.96449 12.438C5.00129 12.3514 5.05497 12.2733 5.12271 12.208C6.63437 10.5564 7.922 9.91229 9.2377 10.1922C9.82559 10.3489 10.3465 10.6931 10.7214 11.1723C10.9644 11.4578 11.2589 11.6952 11.5894 11.8721C12.2333 12.1521 13.241 12.348 15.3405 11.1443C15.4984 11.0729 15.6771 11.0624 15.8421 11.1152C16.0071 11.168 16.1467 11.2805 16.2337 11.4303C16.3208 11.5801 16.3491 11.7566 16.3134 11.9261C16.2776 12.0956 16.1804 12.2459 16.0403 12.3478C14.9972 13.0397 13.7904 13.4453 12.5411 13.5237Z"
      fill="#475467"
    />
  </svg>
);

export default OutsourcingOutsourcing4;
